import classNames from 'classnames/bind';
import { Link } from 'gatsby';
import React, { useEffect, useRef } from 'react';
import GradientBulletPoint from '../../common/GradientBulletPoint/GradientBulletPoint';
import * as styles from './NavItem.module.scss';

const c = classNames.bind(styles);

function NavItem({ active, label, path, withBullet, posHovered }) {
	const dash = useRef(null);
	const self = useRef(null);
	
	useEffect(() => {
		if (dash.current) {
			if (posHovered) {
				const selfBounding = self.current.getBoundingClientRect()
				const dashPos = selfBounding.x + (selfBounding.width / 2)
				const offset = posHovered - dashPos;
				dash.current.style.transform = `translateX(${offset}px)`;
			} else {
				dash.current.style.transform = "";
			}
		}
	}, [posHovered])

	return (
		<>
			<li ref={self}>
				<Link to={path} className={c('link', { active })}>
					{label.toString()}
					{active && <div ref={dash} className={c('dash')}/> }
				</Link>
			</li>
			{withBullet && (
				<li className={c('points')}>
					<GradientBulletPoint color="blue" />
				</li>
			)}
		</>
	);
}

export default NavItem;
