// extracted by mini-css-extract-plugin
export var inMenu = "Menu-module--inMenu--q8ybB";
export var inScroll = "Menu-module--inScroll--53Byl";
export var logo = "Menu-module--logo--+p+rk";
export var navDash = "Menu-module--navDash--Xx7j4";
export var navItems = "Menu-module--navItems--ZA6vI";
export var navigation = "Menu-module--navigation--U44tV";
export var opened = "Menu-module--opened--iPDwf";
export var scrollTransition = "Menu-module--scrollTransition--CVRBp";
export var scrollUp = "Menu-module--scrollUp--3D2Ui";
export var topSection = "Menu-module--topSection--ZvhLQ";
export var wrapper = "Menu-module--wrapper--SZzuX";