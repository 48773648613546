import classNames from 'classnames/bind';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useContext, useState } from 'react';
import AppendHead from 'react-append-head';
import ScreenOrientation from '../components/common/ScreenOrientation/ScreenOrientation';
import Footer from '../components/Footer/Footer';
import Menu from '../components/Menu/Menu';
import Transition from '../components/Transition/Transition';
import { LanguageContext } from '../utils/LanguageContext';
import * as styles from './style.module.scss';

const c = classNames.bind(styles);

function Layout({ children, pageContext, location }) {
	const langCTX = useContext(LanguageContext);
	const [screenRotationClosed, setScreenRotationClosed] = useState(false);

	const dataMenu = useStaticQuery(
		graphql`
			query {
				allWpMenu {
					nodes {
						menuItems {
							nodes {
								path
								label
							}
						}
						locations
					}
				}
			}
		`,
	);
	let itemsMenu;
	for (let i = 0; i < dataMenu.allWpMenu.nodes.length; i++) {
		if (Object.keys(pageContext).length > 0) {
			//if 404
			if (
				pageContext.lang === 'FR' &&
				dataMenu.allWpMenu.nodes[i].locations[0] === 'MAIN'
			) {
				itemsMenu = dataMenu.allWpMenu.nodes[i].menuItems.nodes;
				langCTX.changeLanguage('FR');
			} else if (
				pageContext.lang === 'EN' &&
				dataMenu.allWpMenu.nodes[i].locations[0] === 'MAIN___EN'
			) {
				itemsMenu = dataMenu.allWpMenu.nodes[i].menuItems.nodes;
				langCTX.changeLanguage('EN');
			}
		} else {
			if (
				langCTX.languageCode === 'FR' &&
				dataMenu.allWpMenu.nodes[i].locations[0] === 'MAIN'
			) {
				itemsMenu = dataMenu.allWpMenu.nodes[i].menuItems.nodes;
			} else if (
				langCTX.languageCode === 'EN' &&
				dataMenu.allWpMenu.nodes[i].locations[0] === 'MAIN___EN'
			) {
				itemsMenu = dataMenu.allWpMenu.nodes[i].menuItems.nodes;
			}
		}
	}

	return (
		<div className={c('wrapper')}>
			{!screenRotationClosed && (
				<ScreenOrientation setClosed={() => setScreenRotationClosed(true)} />
			)}
			{/* {process.env.GATSBY_ACTIVE_ENV !== 'production' && (
				<Helmet
					meta={[{ name: 'robots', content: 'noindex' }]}
					script={[
						{
							async: true,
							type: 'text/javascript',
							src:
								'https://www.bugherd.com/sidebarv2.js?apikey=ijx0qsv5kgbtnp2zhxp3la',
						},
					]}
				/>
			)} */}
			{/* <Helmet>
				<script
					src="https://cdn.weglot.com/weglot.min.js"
					onLoad={() => {
						Weglot.initialize({
							api_key: 'wg_30025ceebeb0704cc520446101a26c333'
						});
					}}
				/>
			</Helmet> */}

			<AppendHead>
				<script type="text/javascript" src="https://cdn.weglot.com/weglot.min.js" order="0"></script>
				<script order="1">
					{`
						Weglot.initialize({
							api_key: '${process.env.GATSBY_WEGLOT_KEY_API}',
							button_style: {
								invert_flags: false
							}
						});
					`}
				</script>
			</AppendHead>

			{itemsMenu && (
				<Menu
					navItems={itemsMenu}
					lang={pageContext.lang}
					pathTranslatePage={pageContext.translations}
					location={location}
				/>
			)}

			<Transition location={location}>{children}</Transition>

			<Footer />

			<script
				type="text/javascript"
				src="/rgpd/tarteaucitron/tarteaucitron.js"
			/>
			<script
				type="text/javascript"
				dangerouslySetInnerHTML={{
					__html: `
				tarteaucitron.init({
					"privacyUrl": "${process.env.GATSBY_FRONTEND_URL + '/mentions-legales/'}",
					"hashtag": "#rgpd",
					"cookieName": "tarteaucitron",
					"orientation": "bottom",
					"groupServices": false,
					"showAlertSmall": false,
					"cookieslist": false,
					"closePopup": false,
					"showIcon": false,
					"adblocker": false,
					"DenyAllCta" : true,
					"AcceptAllCta" : true,
					"highPrivacy": true,
					"handleBrowserDNTRequest": false, /* If Do Not Track == 1, disallow all */
					"removeCredit": true,
					"moreInfoLink": false,
					"useExternalCss": false,
					"useExternalJs": false,
					"readmoreLink": "",
					"mandatory": true,
				});

				tarteaucitron.user.gtagUa = 'UA-101234316-1';
				(tarteaucitron.job = tarteaucitron.job || []).push('gtag');
			`,
				}}
			></script>
		</div>
	);
}

export default Layout;
