import classNames from 'classnames/bind';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useContext } from 'react';
import LinkedIn from '../../svg/linkedin.svg';
import Logo from '../../svg/logo.svg';
import LogoFull from '../../svg/logoFull.svg';
import getPageURL from '../../utils/getPageURL';
import { LanguageContext } from '../../utils/LanguageContext';
import * as styles from './Footer.module.scss';

const c = classNames.bind(styles);

function Footer({ className }) {
	const language = useContext(LanguageContext);
	const query = useStaticQuery(graphql`
		query {
			contact: allWpPage(
				filter: { template: { templateName: { eq: "Contact" } } }
			) {
				nodes {
					uri
					language {
						code
					}
				}
			}
			privacy: allWpPage(
				filter: { template: { templateName: { eq: "PrivacyCenter" } } }
			) {
				nodes {
					uri
					language {
						code
					}
				}
			}
			legals: allWpPage(
				filter: { template: { templateName: { eq: "MentionsLegales" } } }
			) {
				nodes {
					uri
					title
					language {
						code
					}
				}
			}
			home: allWpPage(
				filter: { template: { templateName: { eq: "Homepage" } } }
			) {
				nodes {
					uri
					title
					language {
						code
					}
				}
			}
			wp: wp {
				sp {
					options {
						theme {
							acf {
								email_contact
							}
						}
					}
				}
			}
		}
	`);

	const offices = [
		{
			country: 'France',
			address: '65-67 Rue de la Victoire',
			zipCodeCity: '75009 Paris',
		},
		{
			country: 'Israël',
			address: 'King George st 20',
			zipCodeCity: '9426208 Jérusalem',
		},
	];

	return (
		<footer className={c('wrapper', className)}>
			<div className={c('firstSection', className)}>
				<Link
					className={c('logo', 'mobile')}
					to={getPageURL(query.home, language.languageCode)}
				>
					<Logo />
				</Link>
				<Link
					className={c('logo', 'desktop')}
					to={getPageURL(query.home, language.languageCode)}
				>
					<LogoFull full="true" />
				</Link>
				<a
					target="_blank"
					rel="noopener noreferrer"
					className={c('linkedIn')}
					href="https://www.linkedin.com/company/levanaproject/?originalSubdomain=fr"
				>
					<LinkedIn fill="white" />
				</a>
			</div>
			<ul className={c('offices')}>
				{offices.map((office) => (
					<li className={c('office')} key={office.country}>
						<h3 className={c('title')}>{office.country}</h3>
						<p className={c('details')}>{office.address}</p>
						<p className={c('details')}>
							{office.zipCodeCity && office.zipCodeCity}
						</p>
					</li>
				))}
				<li className={c('office')}>
					<h3 className={c('title')}>SQLI</h3>
					<a
						href="https://www.sqli.com/fr-fr"
						target="_blank"
						rel="noopener noreferrer"
					>
						<p className={c('details')}>
							Découvrir SQLI
						</p>
					</a>
				</li>
				<li className={c('office')}>
					<Link to={getPageURL(query.contact, language.languageCode)}>
						<h3 className={c('title')}>Contact</h3>
					</Link>
					<a
						href={'mailto:' + query.wp.sp.options.theme.acf.email_contact}
						target="_blank"
						rel="noopener noreferrer"
					>
						<p className={c('details')}>
							{query.wp.sp.options.theme.acf.email_contact}
						</p>
					</a>
				</li>
			</ul>
			<div className={c('legals')}>
				<Link to={getPageURL(query.legals, language.languageCode)} href="#">
					<p className={c('legalsButton')}>
						{language.languageCode === 'FR' ? 'Mentions légales' : 'Legals'}
						<br />
					</p>
				</Link>
				<Link to={getPageURL(query.privacy, language.languageCode)} href="#">
					<p className={c('tag')}>
						{language.languageCode === 'FR' ? 'Privacy Center' : 'Privacy Center'}
						<br />
					</p>
				</Link>
				<a
					href="https://sweetpunk.com"
					target="_blank"
					rel="noopener noreferrer"
				>
					<p className={c('tag')}>
						{language.languageCode === 'FR'
							? 'Site réalisé par Sweet Punk'
							: 'Powered by Sweet Punk'}
					</p>
				</a>
			</div>
		</footer>
	);
}

export default Footer;
