import React, { useState } from 'react';

export const LanguageContext = React.createContext();

const Provider = props => {
    const [languageCode, setLanguageCode] = useState("FR")

    return (
        <LanguageContext.Provider value={{
            languageCode,
            changeLanguage: (code) => setLanguageCode(code)
        }}>
            {props.children}
        </LanguageContext.Provider>
    )
};

const Wrapper = ({ element }) => (
    <Provider>
        {element}
    </Provider>
);

export default Wrapper;