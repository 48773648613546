import classNames from 'classnames/bind';
import React from 'react';
import * as styles from './GradientBulletPoint.module.scss';

const c = classNames.bind(styles);

function GradientBulletPoint({ className, color }) {
	return <div className={c('wrapper', className, color)}></div>;
}

export default GradientBulletPoint;
