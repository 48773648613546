import classNames from 'classnames/bind';
import React, { useContext } from 'react';
import { LanguageContext } from '../../../utils/LanguageContext';
import * as styles from './BurgerMenu.module.scss';

const c = classNames.bind(styles);

function BurgerMenu({ menuOpen, setMenuOpen }) {
	const language = useContext(LanguageContext);
	return (
		<button
			aria-expanded={menuOpen}
			onClick={() => setMenuOpen(!menuOpen)}
			className={c('wrapper', { isOpen: menuOpen })}
			aria-label={
				language.languageCode === "FR"
					? (menuOpen ? 'Fermer le menu' : 'Ouvrir le menu')
					: (menuOpen ? 'Close menu' : 'Open menu')
			}
		/>
	);
}

export default BurgerMenu;
