import { globalHistory } from '@reach/router';
import classNames from 'classnames/bind';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Logo from '../../svg/logo.svg';
import getPageURL from '../../utils/getPageURL';
import { LanguageContext } from '../../utils/LanguageContext';
import BurgerMenu from './BurgerMenu/BurgerMenu';
import * as styles from './Menu.module.scss';
import NavItem from './NavItem/NavItem';

const c = classNames.bind(styles);

function Menu({ className, navItems, location }) {
	const navigation = useRef(null);
	const dash = useRef(null);
	const language = useContext(LanguageContext);
	const query = useStaticQuery(graphql`
		query {
			homepage: allWpPage(
				filter: { template: { templateName: { eq: "Homepage" } } }
			) {
				nodes {
					uri
					language {
						code
					}
				}
			}
		}
	`);

	const self = useRef(null);
	const mobileHeader = useRef(null);
	const prevOffset = useRef(null);

	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [inScroll, setInScroll] = useState(false);
	const [scrollUp, setScrollUp] = useState(false);
	const [scrollTransition, setScrollTransition] = useState(false);
	const [posHovered, setPosHovered] = useState(null);

	useEffect(() => {
		const unlisten = globalHistory.listen(() => setIsMenuOpen(false));
		return () => {
			unlisten();
		};
	}, []);

	useEffect(() => {
		function handleMouseMove(e) {
			if (e.clientY < 30) {
				setScrollUp(true);
				setScrollTransition(true);
			}
		}
		function handleScroll() {
			if (!self.current) return;

			const offsetHeight =
				self.current.offsetHeight > 0
					? self.current.offsetHeight
					: mobileHeader.current.offsetHeight;
			let { scrollY } = window;

			if (scrollY > offsetHeight) {
				setInScroll(true);
				if (scrollY > prevOffset.current) {
					setScrollUp(false);
				} else if (scrollY < prevOffset.current) {
					setScrollUp(true);
					setScrollTransition(true);
				}
				prevOffset.current = scrollY;
			} else if (scrollY === 0) {
				setInScroll(false);
				setScrollUp(false);
				setScrollTransition(false);
				prevOffset.current = null;
			}
		}
		window.addEventListener('scroll', handleScroll, { passive: true });
		window.addEventListener('mousemove', handleMouseMove, { passive: true });
		return () => {
			window.removeEventListener('mousemove', handleMouseMove);
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	function isActive(current) {
		return location.pathname.includes(current);
	}

	let isHoveringNavHome = false;
	function handleNavOver(e) {
		if (e.target.tagName === 'A' && !e.target.id.includes('weglot')) {
			if (dash.current) {
				const offset =
					e.target.offsetLeft +
					e.target.offsetWidth / 2 -
					dash.current.offsetLeft -
					dash.current.offsetWidth / 2;
				if (!isHoveringNavHome) {
					isHoveringNavHome = true;
					dash.current.style.transition = 'opacity 300ms ease';
					dash.current.style.opacity = 1;
					dash.current.style.transform = `translateX(${offset}px)`;
				} else {
					dash.current.style.transition =
						'opacity 300ms ease, transform 300ms ease';
					dash.current.style.transform = `translateX(${offset}px)`;
				}
			} else {
				setPosHovered(e.target.offsetLeft + e.target.offsetWidth / 2);
			}
		}
	}

	function handleNavMouseLeave() {
		setPosHovered(null);
		if (dash.current) {
			isHoveringNavHome = false;
			dash.current.style.opacity = 0;
		}
	}

	function renderDash() {
		if (location.pathname === '/' || location.pathname === '/en/') {
			return <span className={c('navDash')} ref={dash} />;
		}
	}

	return (
		<header
			className={c('wrapper', className, {
				inScroll,
				scrollUp,
				scrollTransition,
			})}
			ref={self}
		>
			<div
				className={c('topSection', {
					inScroll,
					scrollUp,
					scrollTransition,
				})}
				ref={mobileHeader}
			>
				<Link to={getPageURL(query.homepage, language.languageCode)}>
					<Logo className={c('logo')} />
				</Link>
				<BurgerMenu menuOpen={isMenuOpen} setMenuOpen={setIsMenuOpen} />
			</div>
			<nav className={c('navigation', isMenuOpen && 'opened', 'menu-weglot')}>
				<Link to={getPageURL(query.homepage, language.languageCode)}>
					<Logo className={c('logo', 'inMenu')} />
				</Link>
				<ul
					ref={navigation}
					className={c('navItems')}
					onMouseMove={handleNavOver}
					onMouseLeave={handleNavMouseLeave}
					role="presentation"
				>
					{navItems.map((navItem, i) => (
						<NavItem
							key={i}
							path={navItem.path}
							label={navItem.label}
							withBullet={i + 1 !== navItems.length}
							active={isActive(navItem.path.toLowerCase())}
							posHovered={posHovered}
						/>
					))}
					<li>
						<div
							id="weglot_here"
						>
						</div>
					</li>
					{renderDash()}
				</ul>

				{/* à décommenter quand on voudra réactiver l'anglais */}
				{/* {pathTranslatePage && (
					<LanguageSelector
						activeLanguage={lang}
						pathTranslatePage={pathTranslatePage}
					/>
				)} */}
			</nav>
		</header>
	);
}

export default Menu;
