import React from "react"
import { Transition as ReactTransition, TransitionGroup } from "react-transition-group"


const timeout = 300
const getTransitionStyles = {
    entering: {
        position: `absolute`,
        opacity: 0,
    },
    entered: {
        transition: `opacity ${timeout}ms ease-in-out`,
        opacity: 1,
    },
    exiting: {
        transition: `opacity ${timeout}ms ease-in-out`,
        opacity: 0,
    },
}

class Transition extends React.PureComponent {
    render() {
        const { children, location } = this.props

        function scrollTop() {
            window.scrollTo(0, 0)
        }
        
        return (
            <TransitionGroup>
                <ReactTransition
                    key={location.pathname}
                    timeout={{
                        enter: timeout,
                        exit: timeout,
                    }}
                    onExited={scrollTop}
                >
                {status => (
                    <div
                        style={{
                        ...getTransitionStyles[status],
                        }}
                    >
                        {children}
                    </div>
                )}
                </ReactTransition>
            </TransitionGroup>
        )
    }
}
export default Transition