import classNames from 'classnames/bind';
import React, { useContext } from 'react';
import RotateSVG from '../../../svg/rotate-icon.svg';
import { LanguageContext } from '../../../utils/LanguageContext';
import * as styles from './ScreenOrientation.module.scss';

const c = classNames.bind(styles);

function ScreenOrientation({ className, setClosed }) {
	const language = useContext(LanguageContext);

	return (
		<div className={c('wrapper', className)}>
			<div className={c('inner')}>
				<RotateSVG />
				<p className={c('message')}>
					{
						language.languageCode === "FR"
							? "Veuillez retourner votre appareil afin de profiter pleinement de l'expérience"
							: "To get the best of your visit please rotate your device"
					}
				</p>
			</div>
			<button
				onClick={setClosed}
				className={c('close')}
				aria-label={
					language.languageCode === "FR"
						? 'Fermer l\'alerte'
						: 'Close alert'
				}
			/>
		</div>
	);
}

export default ScreenOrientation;
