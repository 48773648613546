// extracted by mini-css-extract-plugin
export var desktop = "Footer-module--desktop--lbFEq";
export var details = "Footer-module--details--kDYpF";
export var firstSection = "Footer-module--firstSection---oisl";
export var legals = "Footer-module--legals--tZ0oY";
export var legalsButton = "Footer-module--legalsButton--XzUXL";
export var linkedIn = "Footer-module--linkedIn--9TCn8";
export var logo = "Footer-module--logo--Lu+fA";
export var mobile = "Footer-module--mobile--a074h";
export var office = "Footer-module--office--+n82P";
export var offices = "Footer-module--offices--a0hxr";
export var tag = "Footer-module--tag--0Hf9Z";
export var title = "Footer-module--title--bTyDR";
export var wrapper = "Footer-module--wrapper--RlZJ5";